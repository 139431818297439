// src/components/Car1.js

import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Card, CardContent } from '@mui/material';
import { Link } from 'react-router-dom';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import CustomLightbox from './CustomLightbox';
import carData from '../data/carData'; // Import the car data
import styles from '../styles/VanSection.module.css';

const Car1 = () => {
  const [car, setCar] = useState({ specifications: [], galleryImages: [] });
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  useEffect(() => {
    const foundCar = carData.find(c => c.id === 1); // Find the car with id 1
    if (foundCar) setCar(foundCar);
  }, []);

  const openLightbox = (index) => {
    setPhotoIndex(index);
    setLightboxOpen(true);
  };

  return (
    <Box className={styles.container}>
      <Box className={styles.header}>
        <Typography variant="h4">{car.name?.toUpperCase()}</Typography>
        <Typography variant="subtitle1">Леки Автомобили</Typography>
        <Box className={styles.navLinks}>
          <Link to="/car1">VOLKSWAGEN UP 1.0I</Link>
          <Link to="/car2">DACIA SANDERO 1.0I</Link>
        </Box>
      </Box>
      <Box className={styles.section}>
        <Grid container spacing={4} alignItems="center" justifyContent="center">
          <Grid item xs={12} md={6} position="relative">
            <img src={car.mainImage} alt={car.name} className={styles.vanImage} onClick={() => openLightbox(0)} />
            <FullscreenIcon className={styles.fullscreenIcon} onClick={() => openLightbox(0)} />
          </Grid>
          <Grid item xs={12} md={6} textAlign="center">
            <Typography variant="h5" gutterBottom>
              {car.name}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {car.year}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {car.license}
            </Typography>
            <Typography variant="body1" gutterBottom>
              <span className={styles.price}>{car.price}</span>
            </Typography>
            <Typography variant="body1" gutterBottom>
              {car.descr}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {car.descr1}
            </Typography>
            <Typography variant="body1" gutterBottom style={{ marginTop: '10px' }}>
              <span className={styles.deposit}>Депозит: {car.deposit}</span>
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box className={styles.tableContainer}>
        {car.specifications.map((spec, index) => (
          <Card key={index} className={styles.table}>
            <CardContent>
              <Typography variant="h6" className={styles.tableHeader}>{spec.title}</Typography>
              <ul>
                {spec.details.map((detail, idx) => (
                  <li key={idx}>{detail}</li>
                ))}
              </ul>
            </CardContent>
          </Card>
        ))}
      </Box>
      <Typography variant="h4" className={styles.galleryTitle}>
        ГАЛЕРИЯ
      </Typography>
      <Box className={styles.gallery}>
        {car.galleryImages.map((image, index) => (
          <Box
            key={index}
            className={styles.galleryItem}
            style={{ backgroundImage: `url(${image})` }}
            onClick={() => openLightbox(index)}
          >
            <FullscreenIcon className={styles.galleryfullscreenIcon} />
          </Box>
        ))}
      </Box>
      {lightboxOpen && (
        <CustomLightbox
          images={car.galleryImages}
          photoIndex={photoIndex}
          setPhotoIndex={setPhotoIndex}
          onClose={() => setLightboxOpen(false)}
        />
      )}
    </Box>
  );
};

export default Car1;
