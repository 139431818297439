// src/data/carData.js

import car1Image from '../assets/images/photo12.jpeg'; // Main image for Car1
import car2Image from '../assets/images/photo16.jpeg'; // Main image for Car2
import galleryImage1 from '../assets/images/photo12.jpeg'; // Gallery images for Car1
import galleryImage2 from '../assets/images/photo13.jpeg';
import galleryImage3 from '../assets/images/photo14.jpeg';
import galleryImage4 from '../assets/images/photo15.jpeg'; // Gallery images for Car2
import galleryImage5 from '../assets/images/photo16.jpeg';
import galleryImage6 from '../assets/images/photo17.jpeg';
import galleryImage7 from '../assets/images/photo18.jpeg';
import galleryImage8 from '../assets/images/photo19.jpeg';



const carData = [
  {
    id: 1,
    name: 'Volkswagen Up 1.0i',
    year: 'Година на производство: 2014г.',
    price: 'Цена за наем на ден(24 часа): 40,00 лв. (без ДДС)',
    descr: 'Пробег: без ограничение.',
    //descr1: '(Над пробега - 0.15 лв. на КМ без ДДС)',
    deposit: '150 лв',
    mainImage: car1Image,
    galleryImages: [galleryImage1, galleryImage2, galleryImage3, galleryImage4],
    specifications: [
      {
        title: 'Характеристики:',
        details: [
          'Задвижване - предно',
          'Гориво - бензин',
          'Европейска екологична норма - Euro 5B',
          'Врати - 5бр.',
          'Брой места - 3+1',
          'Товаримост - 670кг.',
          'Скоростна кутия - роботизирана',
          'Брой предавки - 5ск.',
          'Категория - В',
          'Климатик',
          'Централно заключване',
          'ABS',
          'Стерео уредба',
          'Въздушни възглавници',
        ],
      },
    ],
  },
  {
    id: 2,
    name: 'Dacia Sandero 1.0i',
    year: 'Година на производство: 2018г.',
    price: 'Цена за наем на ден(24 часа): 40,00 лв. (без ДДС)',
    descr: 'Пробег: без ограничение.',
    //descr1: '(Над пробега - 0.15 лв. на КМ без ДДС)',
    deposit: '150 лв',
    mainImage: car2Image,
    galleryImages: [galleryImage5, galleryImage6, galleryImage7, galleryImage8],
    specifications: [
      {
        title: 'Характеристики:',
        details: [
          'Задвижване - предно',
          'Гориво - бензин',
          'Европейска екологична норма - Euro 6C',
          'Врати - 5бр.',
          'Брой места - 3+1',
          'Товаримост - 810кг.',
          'Скоростна кутия - механична',
          'Брой предавки - 5ск.',
          'Категория - В',
          'Климатик',
          'Централно заключване',
          'ABS',
          'Стерео уредба',
          'Въздушни възглавници',
        ],
      },
    ],
  },
];

export default carData;
