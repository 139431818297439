import React, { useState } from 'react';
import { Box, Typography, Grid, Card, CardContent } from '@mui/material';
import { Link } from 'react-router-dom';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import CustomLightbox from './CustomLightbox';
import trailerData from '../data/trailerData'; // Ensure trailerData has similar structure to vanData
import styles from '../styles/VanSection.module.css';

const Trailer1 = () => {
  // Find the trailer with id 1, similar to how vanData is accessed
  const trailer = trailerData.find(t => t.id === 1); 
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const openLightbox = (index) => {
    setPhotoIndex(index);
    setLightboxOpen(true);
  };

  return (
    <Box className={styles.container}>
      <Box className={styles.header}>
        <Typography variant="h4">{trailer.name.toUpperCase()}</Typography>
        <Typography variant="subtitle1">Ремаркета</Typography>
        <Box className={styles.navLinks}>
          <Link to="/trailer1">STEMA FT 7525</Link>
        </Box>
      </Box>
      <Box className={styles.section}>
        <Grid container spacing={4} alignItems="center" justifyContent="center">
        <Grid item xs={12} md={6} position="relative">
  <img
    src={trailer.mainImage} // Ensure trailer.mainImage is available
    alt={trailer.name}
    className={styles.vanImage}  // If vanImage is used for both van and trailer, it's fine
    onClick={() => openLightbox(0)}  // Ensure function references are correct
  />
  <FullscreenIcon
    className={styles.fullscreenIcon}
    onClick={() => openLightbox(0)}  // Properly close the parentheses and curly braces
  />
</Grid>

          <Grid item xs={12} md={6} textAlign="center">
            <Typography variant="h5" gutterBottom>
              {trailer.name}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {trailer.year}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {trailer.license}
            </Typography>
            <Typography variant="body1" gutterBottom>
              <span className={styles.price}>{trailer.price}</span>
            </Typography>
            <Typography variant="body1" gutterBottom style={{ marginTop: '10px' }}>
              <span className={styles.deposit}>Депозит: {trailer.deposit}</span>
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box className={styles.tableContainer}>
        {trailer.specifications.map((spec, index) => (
          <Card key={index} className={styles.table}>
            <CardContent>
              <Typography variant="h5" className={styles.tableHeader}>
                {spec.title}
              </Typography>
              <ul>
                {spec.details.map((detail, idx) => (
                  <li key={idx}>{detail}</li>
                ))}
              </ul>
            </CardContent>
          </Card>
        ))}
      </Box>
      <Typography variant="h5" className={styles.galleryTitle}>
        ГАЛЕРИЯ
      </Typography>
      <Box className={styles.gallery}>
        {trailer.galleryImages.map((image, index) => (
          <Box
            key={index}
            className={styles.galleryItem}
            style={{ backgroundImage: `url(${image})` }}
            onClick={() => openLightbox(index)}
          >
            <FullscreenIcon className={styles.galleryfullscreenIcon} />
          </Box>
        ))}
      </Box>
      {lightboxOpen && (
        <CustomLightbox
          images={trailer.galleryImages}
          photoIndex={photoIndex}
          setPhotoIndex={setPhotoIndex}
          onClose={() => setLightboxOpen(false)}
        />
      )}
    </Box>
  );
};

export default Trailer1;
